/* eslint-disable @typescript-eslint/naming-convention */
import {
  createAxiosInstance,
  type AxiosError,
  type InternalAxiosRequestConfig,
} from '@astronautsid/wpe-utils/helpers/axiosIntance'

import { getIronSession } from 'iron-session'

import { axiosErrorLoggerInterceptor } from 'config/axiosLogger'

import { SessionDataType, sessionOptions } from 'lib/session/config'
import { getHeadersConfig } from 'utils/helpers/getHeadersConfig'
import { parseCookie } from 'utils/cookie'
import { TOKEN_COOKIE_NAME } from 'config/constants'

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || ''

const isServer = typeof window === 'undefined'

const handleResponseReject = (error: AxiosError) => {
  if (!isServer) {
    axiosErrorLoggerInterceptor(error)
  }

  return Promise.reject(error)
}

const getToken = async () => {
  if (isServer) {
    const { cookies } = await import('next/headers')
    const session = await getIronSession<SessionDataType>(cookies(), sessionOptions)
    return session.token
  }

  // run on client
  const cookies = parseCookie(document.cookie)

  return cookies[TOKEN_COOKIE_NAME] || null
}

const requestInterceptor = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  // No Re-assign headers.Authorization if exist
  if (config.headers.Authorization) {
    return config
  }

  let token = await getToken()
  token = !config.url?.startsWith(process.env.NEXT_PUBLIC_API_BASE_URL as string) ? token : null

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${String(token)}` : null

  return config
}

const axiosInstanceApiGateway = createAxiosInstance(
  {
    baseURL: API_BASE_URL,
    headers: getHeadersConfig(),
    maxRedirects: 0,
    timeout: 60000, // 1 minutes timeout since default timeout is disabled.
  },
  {
    interceptorResponseReject: handleResponseReject,
    interceptorRequest: requestInterceptor as never,
  },
)

export default axiosInstanceApiGateway
export type ConfigAxiosType = Parameters<(typeof axiosInstanceApiGateway)['Get']>['0']['config']
